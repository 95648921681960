import React, { CSSProperties } from 'react';

import { GestureEvent } from '../../../../Events/withEvent';
import { ThemeRes } from '../../../../Web/Themes/ThemeRes';
import { useMultiTouch } from '../../Context/GestureContext';
import { useMapZoomScaleWithLimit, useScaleCoteSizeText } from './Walls/ZoomScaleComponents';

export const CoteConstants = {
    quote_unit: 'cm',

    shift: 7,
    offsetShift: 25,
    labelHeight: 24,
    distanceLineEndObj: 5,
    arrowSize: 8,
    charMonospace: 9.5,
    densedDisplayRuptureValue: 80,
};

type CoteLineProps = { measure: number };
export const CoteLine = ({ measure }: CoteLineProps) => {
    const props: React.SVGProps<SVGLineElement> = {
        x1: -measure / 2,
        y1: 0,
        x2: measure / 2,
        y2: 0,
        stroke: '#424448',
        strokeWidth: 1,
    };

    return <line {...props} />;
};

type CoteLineEndProps = { x: number; shiftValue: number; reversed: boolean; inOut: string };
export const CoteLineEnd = ({ x, shiftValue, reversed, inOut }: CoteLineEndProps) => {
    const props = {
        x1: x,
        y1: reversed && inOut !== 'inside' ? 8 : -8,
        x2: x,
        y2:
            -shiftValue +
            //@ts-ignore
            (reversed ^ (inOut === 'inside') ? CoteConstants.distanceLineEndObj : -CoteConstants.distanceLineEndObj),
        stroke: '#464646a6', //side != 'inside' ? "#c0c0c0" : "#263740",
        strokeWidth: 1,
    };
    return <line {...props} />;
};
type CoteEndArrowProps = { x: number; reversed?: boolean };
export const CoteEndArrow = ({ x, reversed }: CoteEndArrowProps) => {
    const arrowSize = CoteConstants.arrowSize;
    const props = {
        points: reversed
            ? x + ',' + 0 + ' ' + (x + arrowSize) + ',' + arrowSize / 2 + ' ' + (x + arrowSize) + ',' + -arrowSize / 2
            : x + ',' + 0 + ' ' + (x - arrowSize) + ',' + arrowSize / 2 + ' ' + (x - arrowSize) + ',' + -arrowSize / 2,
        fill: '#424448',
    };
    return <polygon {...props} />;
};

type CoteLabelProps = { textLength: number; highlight?: boolean };
export const CoteLabel = ({ textLength, highlight }: CoteLabelProps) => {
    const labelWidth = CoteConstants.charMonospace * textLength;
    const labelHeight = useScaleCoteSizeText(CoteConstants.labelHeight);
    const props = {
        width: labelWidth,
        height: labelHeight,
        x: -labelWidth / 2,
        y: -labelHeight / 2,
        fill: '#FFFFFF',
        stroke: highlight ? '#424448' : '#D6D6D6',
        strokeWidth: highlight ? 2 : 1,
    };
    return <rect {...props} style={{ cursor: 'pointer' }} />;
};

type CoteSizeTextProps = { text: string; fontSize: number; angle: number; onClick: (e: GestureEvent) => void };
export const CoteSizeText = ({ text, fontSize = 14, angle, onClick }: CoteSizeTextProps) => {
    const style: CSSProperties = {
        cursor: 'pointer',
        transform: angle === -270 || angle === 90 ? 'rotate(180deg)' : 'none',
    };

    const multiTouch = useMultiTouch();
    const handleTouchEnd = (event: GestureEvent) => {
        if (!multiTouch.handleMultiTouch(event as React.TouchEvent)) {
            onClick(event);
        }
    };

    const scale = useMapZoomScaleWithLimit();
    return (
        <text
            y={5 * scale}
            textAnchor="middle"
            stroke="#FFFFFF"
            strokeWidth={0.2}
            fontFamily={ThemeRes.Fonts.fontFamilyNunitoSans}
            fontWeight={400}
            fill="#424448"
            style={style}
            fontSize={fontSize}
            onClick={onClick}
            onTouchEnd={handleTouchEnd}>
            {text}
        </text>
    );
};

export class CoteUtils {
    public static calculateShift = (side: string, angle: number) => {
        let shiftValue = side === 'inside' ? CoteConstants.shift * 5 : -CoteConstants.shift * 5;
        let reversed = false;
        if (angle > 90 || angle < -89) {
            angle -= 180;
            reversed = true;
            shiftValue = -shiftValue;
        }
        return { angle, shiftValue, reversed };
    };

    public static calculateDensedShiftOffset(reversed: boolean, densedDisplay: boolean) {
        if (!densedDisplay) {
            return 0;
        }
        return reversed ? CoteConstants.offsetShift : -CoteConstants.offsetShift;
    }
}
