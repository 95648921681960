import { useMapViewerContext } from "../../../Context/MapViewerContext";

const ZOOM_LIMIT = 0.33;

export const useMapZoomScale = () => {
    const mapZoom = useMapViewerContext().value?.a ?? 1;
    const scale = mapZoom > 1 ? 1 : 1 / mapZoom;
    return scale;
};


export function toCoteSizeText(defaultSize: number, mapZoom: number = 1): number {
    let fontSize = 14;
    let keepSize = 14;
    const textSize = fontSize * mapZoom;
    if (textSize > defaultSize) {
        fontSize = defaultSize;
        console.log('useMapViewerContext max', { fontSize, mapZoom, textSize, defaultSize });
    } else if (textSize < keepSize && textSize > 5) {
        fontSize = keepSize;
        console.log('useMapViewerContext keep size', { fontSize, mapZoom, textSize, defaultSize });
    } else {
        fontSize = textSize;
        console.log('useMapViewerContext return', { fontSize, mapZoom, textSize, defaultSize });
    }

    return fontSize;
}

export function useScaleCoteSizeText(defaultSize: number = 14): number {
    const mapZoom = useMapViewerContext().value.a;
    const scale = (mapZoom > 1 || mapZoom < ZOOM_LIMIT) ? 1 : 1 / mapZoom;
    const size = scale * defaultSize;
    return size;
}

export const useTextSizeRectWidth = (sizeTextLength: number) => {
    const mapZoom = useMapViewerContext().value.a;
    const scale = (mapZoom > 1 || mapZoom < ZOOM_LIMIT) ? 1 : 1 / mapZoom;
    const textSizeRectWidth = sizeTextLength * scale;
    return textSizeRectWidth;
}

export const useMapZoomScaleWithLimit = () => {
    const mapZoom = useMapViewerContext().value?.a ?? 1;
    const scale = (mapZoom > 1 || mapZoom < ZOOM_LIMIT) ? 1 : 1 / mapZoom;
    return scale;
};