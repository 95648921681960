import React from 'react';

import { GestureEvent } from '../../../../../Events/withEvent';
import { useGestureContext } from '../../../Context/GestureContext';
import { useMapContext, useMapContextState } from '../../../Context/MapContext';
import { MapConstants } from '../../../MapConstants';
import { IOpening } from '../../../Models/IOpening';
import { IRoom } from '../../../Models/IRoom';
import { IRoomItem } from '../../../Models/IRoomItem';
import { qSVG } from '../../../Utils/qSVG';
import { RoomMove } from '../../../Utils/Room/RoomMove';
import { CoordPoint, Wall } from '../../../Utils/Types';
import { WallMove } from '../../../Utils/Wall/WallMove';
import { WallUtils } from '../../../Utils/Wall/WallUtils';
import { CoteConstants, CoteEndArrow, CoteLabel, CoteLine, CoteLineEnd, CoteSizeText, CoteUtils } from '../Cotes';
import { EditCoteDialog } from './EditCoteDialog';
import { useScaleCoteSizeText, useTextSizeRectWidth } from './ZoomScaleComponents';
import { OpeningUtils } from '../../../Utils/Opening/OpeningUtils';

type BoxRibsProps = {
    readonly?: boolean;
    roomId?: string;
    walls?: Array<Wall>;
    rooms?: Array<IRoom>;
    roomItems?: Array<IRoomItem>;
    selectedRoomId?: string;
    selectedRoomItemId?: string;
    selectedOpeningId?: string;
};
export const MapBoxRoomsRibs = ({ readonly, roomId }: BoxRibsProps) => {
    const {
        walls = [],
        rooms = [],
        roomItems = [],
        selectedRoomId,
        selectedRoomItemId,
        selectedOpeningId,
    } = useMapContextState();
    return (
        <RawBoxRoomsRibs
            walls={walls}
            rooms={rooms}
            roomItems={roomItems}
            selectedRoomId={selectedRoomId}
            selectedRoomItemId={selectedRoomItemId}
            selectedOpeningId={selectedOpeningId}
            readonly={readonly}
            roomId={roomId}
        />
    );
};

export const RawBoxRoomsRibs = ({
    readonly,
    roomId,
    walls: ribWalls = [],
    rooms = [],
    roomItems = [],
    selectedRoomId,
    selectedRoomItemId,
    selectedOpeningId,
}: BoxRibsProps) => {
    const gesture = useGestureContext().state;
    const ribWallsToUse = ribWalls.filter((wall) => wall.roomId);
    const adjacentWalls = WallUtils.getWallsAdjacentList(ribWallsToUse, ribWallsToUse, roomItems);

    const showCotes = (room: IRoom) =>
        readonly ||
        (selectedRoomItemId || selectedOpeningId || gesture.roomItemId
            ? false
            : selectedRoomId
            ? room.roomId === selectedRoomId
            : true);
    return (
        <React.Fragment>
            {rooms
                .filter((item) => (roomId ? item.roomId === roomId : true))
                .filter(showCotes)
                .map((room) => {
                    return (
                        <BoxWallCotes
                            key={`BoxWallCotes-Room-${room.roomId}`}
                            roomId={room.roomId!}
                            ribWalls={room.walls!}
                            adjacentWalls={adjacentWalls}
                            insideCoteOnly={readonly}
                        />
                    );
                })}
        </React.Fragment>
    );
};

type BoxWallRibsProps = {
    roomId: string;
    ribWalls: Array<Wall>;
    adjacentWalls: Array<Array<Wall>>;
    withAdjacentCotes?: boolean;
    insideCoteOnly?: boolean;
};
const BoxWallCotes = ({ roomId, ribWalls, adjacentWalls, insideCoteOnly }: BoxWallRibsProps) => {
    const wallSize = (wall: Wall) => (qSVG.measure(wall.start, wall.end) / MapConstants.meter) * 100;
    const [coteWallToEdit, setCoteWallToEdit] = React.useState<Wall>();
    const map = useMapContext();

    const onValidate = (dimens: number) => {
        if (coteWallToEdit) {
            const { walls = [], rooms = [], openings = [] } = map.getState();
            //wallResize(coteWallToEdit, dimens);
            const wall = WallMove.getResizeBindedWall(coteWallToEdit)!; //binding one of the siblings wall to make the resize;

            let moveResult = WallMove.prepareWallMove(coteWallToEdit, wall, dimens, openings, rooms, walls);
            // const wall = WALLS[2];
            if (!moveResult.wallMoveSuccess) {
                // return 1 fail // before = 1
                const wall = WallMove.getResizeBindedWall(coteWallToEdit, false, true)!; //retry by resetting CoteManuallySetted
                moveResult = WallMove.prepareWallMove(coteWallToEdit, wall, dimens, openings, rooms, walls);
            }

            map.update({
                values: {
                    walls: moveResult.walls,
                    rooms: moveResult.rooms,
                    openings: moveResult.openings,
                    busy: false,
                },
                mapModified: true,
                logs: { event: 'BoxWallCotes onValidate', dimens },
            });
            setCoteWallToEdit(undefined);
            const room = rooms.find((x) => x.roomId === wall.roomId);
            if (room) {
                OpeningUtils.resetOpeningsPlacement(moveResult.openings, moveResult.walls);
            }
        }
    };

    const getWallLimits = (coteWallToEdit: Wall) => {
        const { walls = [], rooms = [], openings = [], roomItems = [] } = map.getState();
        return getMinMaxResizeWall(coteWallToEdit, walls, openings, rooms, roomItems);
    };

    const props: React.SVGProps<SVGGElement> = {
        id: RoomMove.roomRibsId(roomId),
        className: RoomMove.class(roomId),
    };
    return (
        <React.Fragment>
            <g {...props}>
                {ribWalls.map((wall, index) => {
                    const adjWall = adjacentWalls.find((x) => x[0] === wall);
                    return (
                        <WallRibCote
                            metaId="wall¤wallStart¤wallEnd" //metaId
                            attrs={props}
                            key={index}
                            roomId={wall.roomId!}
                            p1={wall.start}
                            p2={wall.end}
                            inOut={adjWall || insideCoteOnly ? 'inside' : 'outside'}
                            side={wall.sideName!}
                            value={wallSize(wall)}
                            angle={wall.angle * (180 / Math.PI)}
                            coteMeasureOffset={insideCoteOnly ? MapConstants.wallThickness : 0}
                            highlight={
                                coteWallToEdit
                                    ? wall.roomId === coteWallToEdit.roomId && wall.sideName === coteWallToEdit.sideName
                                    : false
                            }
                            editCote={(e) => setCoteWallToEdit(wall)}
                        />
                    );
                })}
            </g>
            {coteWallToEdit && (
                <EditCoteDialog
                    value={wallSize(coteWallToEdit).toFixed(0)}
                    onValidate={onValidate}
                    onClose={() => setCoteWallToEdit(undefined)}
                    getLimit={() => getWallLimits(coteWallToEdit)}
                />
            )}
        </React.Fragment>
    );
};

type WallRibCoteProps = {
    roomId: string;
    p1: CoordPoint; //start
    p2: CoordPoint; //end
    inOut: string;
    side: string;
    value: number;
    angle: number;
    metaId: string;
    coteMeasureOffset: number;
    attrs: React.SVGProps<SVGGElement>;

    highlight?: boolean;
    editCote: (e?: GestureEvent) => void;
};
const WallRibCote = ({
    roomId,
    p1,
    p2,
    inOut,
    side,
    value,
    angle,
    metaId,
    coteMeasureOffset = 0,
    highlight,
    editCote,
}: WallRibCoteProps) => {
    const startText = qSVG.middle(p1.x, p1.y, p2.x, p2.y);
    const measure = qSVG.measure(p1, p2) - coteMeasureOffset;
    const densedDisplay = value < CoteConstants.densedDisplayRuptureValue;

    const sizeText = value.toFixed(0) + ' ' + CoteConstants.quote_unit;
    const sizeTextLength = useTextSizeRectWidth(sizeText.length);
    const fontSize = useScaleCoteSizeText();

    const { angle: newAngle, shiftValue, reversed } = CoteUtils.calculateShift(inOut, angle);
    const densedShiftOffset = CoteUtils.calculateDensedShiftOffset(reversed, densedDisplay);

    const props: React.SVGProps<SVGGElement> = {
        id: `wall¤${roomId}¤${side}¤${value.toFixed(0)}${metaId ? '¤' + metaId : ''}`,
        //@ts-ignore
        value: value,
        className: 'cote',
        transform: `rotate(${newAngle} ${startText.x},${startText.y}) translate(${startText.x},${
            startText.y + (shiftValue + densedShiftOffset)
        })`,
    };

    return (
        <g {...props}>
            <CoteLine measure={measure} />
            <CoteLineEnd
                x={-measure / 2}
                shiftValue={shiftValue + densedShiftOffset}
                reversed={reversed}
                inOut={inOut}
            />
            <CoteLineEnd
                x={measure / 2}
                shiftValue={shiftValue + densedShiftOffset}
                reversed={reversed}
                inOut={inOut}
            />
            <CoteEndArrow x={-measure / 2} reversed />
            <CoteEndArrow x={measure / 2} />

            <CoteLabel textLength={sizeTextLength} highlight={highlight} />
            <CoteSizeText text={sizeText} fontSize={fontSize} angle={newAngle} onClick={editCote} />
        </g>
    );
};

const getMinMaxResizeWall = (
    wall: Wall,
    walls: Array<Wall>,
    openings: Array<IOpening> = [],
    rooms: Array<IRoom> = [],
    roomItems: Array<IRoomItem>
) => {
    let min = 0;
    let max = 0;

    // switch (coteType) {
    //     case 'wall':
    //         minValue = (wall.minimumSize * 100) / meter;
    //         maxValue = Math.round(wallResizeSimulate(wall));
    //         break;
    //     case 'objfocus':
    //         if (start === end) {
    //             maxValue = Math.round((qSVG.measure(wall.start, wall.end) * 100) / meter);
    //         } else if (start === 'wallstart' || OBJDATA[end] === binder.obj) {
    //             const nextCoteValue = parseFloat(
    //                 $('#' + rawId)
    //                     .next()
    //                     .next()
    //                     .attr('value')
    //             );
    //             maxValue = Math.round(parseFloat(value) + nextCoteValue);
    //         } else if (end === 'wallend' || OBJDATA[start] === binder.obj) {
    //             const prevCoteValue = parseFloat(
    //                 $('#' + rawId)
    //                     .prev()
    //                     .prev()
    //                     .attr('value')
    //             );
    //             maxValue = Math.round(parseFloat(value) + prevCoteValue);
    //         }
    //         break;
    //     case 'adjacent':
    //         minValue = 0;
    //         maxValue = Math.round(parseFloat(value) + qSVG.measure(wall.start, wall.end) - wall.minimumSize);
    //         break;
    // }

    //coteType: 'wall':
    min = (wall.minimumSize! * 100) / MapConstants.meter;
    console.log('getMinMaxResizeWall called in BoxRibs', {});
    max = Math.round(WallMove.wallResizeSimulate(wall, openings, rooms, roomItems, walls));

    return { min, max };
};
