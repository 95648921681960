import { styled } from '@material-ui/styles';
import React, { CSSProperties, forwardRef } from 'react';

import { Logger } from '../../../../Errors/Logger';
import { Message } from '../../../../Locales/I18nService';
import { LogLevelType } from '../../../../Services/Analytics/LogLevelType';
import { ThemeRes } from '../../../../Web/Themes/ThemeRes';
import { useMapContextState } from '../../Context/MapContext';
import { IOpening } from '../../Models/IOpening';
import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { PointUtils } from '../../Utils/PointUtils';
import { BoundingBox, CoordPoint, Wall } from '../../Utils/Types';
import { RawCarpentryItems } from '../Widgets/Openings/BoxCarpentryItems';
import { RawBoxRoomItems } from '../Widgets/RoomItems/BoxRoomItems';
import { RawBoxAreas } from '../Widgets/Rooms/BoxAreas';
import { RawBoxRoomsRibs } from '../Widgets/Walls/BoxWallRibs';
import { RawBoxWalls } from '../Widgets/Walls/BoxWalls';
import { ExportMapImageHelper } from './Helpers/ExportMapPngHelper';
import { SvgHelpers } from './Helpers/SvgGenerationHelpers';
type ExportMapSvgV3Props = { style?: CSSProperties };

export const useExportMapSvgV3 = ({ style }: ExportMapSvgV3Props = {}) => {
    const svgWrapper = React.useRef<SVGSVGElement>(null);
    const mapState = useMapContextState();

    const captureSvg = (toBase64: boolean = true) => SvgHelpers.capture(svgWrapper.current?.outerHTML, toBase64);

    const capturePng = () => {
        const svgImage = captureSvg();
        if (!svgImage) {
            Logger.logAnalytics({
                level: LogLevelType.Warning,
                type: 'NULL_SVG_IMAGE',
                data: {
                    svgWrapper: svgWrapper.current?.outerHTML,
                    document: document.getElementById('MapSvgV3')?.outerHTML,
                    roomLength: mapState?.rooms?.length,
                },
            });
            return Promise.resolve('');
        }
        return ExportMapImageHelper.exportMapPng(svgImage);
    };

    return {
        captureSvg,
        capturePng,
        mapView: (
            <ExportMapView
                ref={svgWrapper}
                walls={mapState.walls}
                rooms={mapState.rooms}
                openings={mapState.openings}
                roomItems={mapState.roomItems}
                style={style}
            />
        ),
    };
};

type ExportMapViewProps = {
    walls?: Array<Wall>;
    rooms?: Array<IRoom>;
    roomItems?: Array<IRoomItem>;
    openings?: Array<IOpening>;
    showLegend?: boolean;
    rotationAngle?: number;
    style?: CSSProperties;
    exportMode?: boolean;
};

export const ExportMapView = forwardRef<SVGSVGElement, ExportMapViewProps>(
    (
        {
            style,
            walls = [],
            rooms = [],
            openings = [],
            roomItems = [],

            showLegend = true,
            rotationAngle = 0,
            exportMode,
        },
        ref
    ) => {
        const legendHeight = 50;
        const margin = rotationAngle !== 0 ? 300 : 100;
        const boxMargin = margin / 5;
        const exportWidth = 1080;

        const points = [
            ...walls.map((x) => x.start),
            ...roomItems.reduce<Array<CoordPoint>>((items, x) => [...items, ...(x.coordsReal || [])], []),
        ];

        const overallBBox = PointUtils.calculateBoundingBox({ points: points })!;

        if (!overallBBox) {
            return null;
        }

        const laizeTraceDesciptionWidth = 100;

        const xMin = overallBBox.xMin - boxMargin;
        const yMin = overallBBox.yMin - boxMargin;
        const xMax = overallBBox.xMax + boxMargin;
        const yMax = overallBBox.yMax + boxMargin + legendHeight;
        const width = xMax - xMin + laizeTraceDesciptionWidth;
        const height = yMax - yMin + legendHeight;

        return (
            <Container id="ExportMapSvgV3" style={style}>
                <svg
                    id="MapSvgV3"
                    ref={ref}
                    xmlns="http://www.w3.org/2000/svg" //!\ IMPORTANT FOR EXPORT
                    viewBox={`${xMin} ${yMin} ${width} ${height}`}
                    width={exportMode ? exportWidth : width}
                    height={exportMode ? (exportWidth * height) / width : height}
                    transform={`rotate(${rotationAngle} 0 0)`}>
                    <g id="planGroup">
                        <g id="boxwall">
                            <RawBoxWalls rooms={rooms} readonly />
                        </g>
                        <g id="boxcarpentry">
                            <RawCarpentryItems openings={openings} readonly />
                        </g>
                        <g id="boxRoomItem">
                            <RawBoxRoomItems roomItems={roomItems} readonly />
                        </g>
                        <g id="boxArea">
                            <RawBoxAreas rooms={rooms} readonly />
                        </g>
                        <g id="boxCote">
                            <RawBoxRoomsRibs rooms={rooms} readonly />
                        </g>
                    </g>
                    {showLegend && <Legend overallBBox={overallBBox} margin={margin} />}
                </svg>
            </Container>
        );
    }
);

const Container = styled('div')({ display: 'none' });

//#region  //* LEGEND

type LegendProps = { overallBBox: BoundingBox; margin: number };
const Legend = ({ overallBBox, margin }: LegendProps) => {
    return (
        <g id="Legend">
            <LegendText
                id="LegendInfo"
                x={overallBBox.xMin}
                y={overallBBox.yMax + margin}
                fontFamily={ThemeRes.Fonts.fontFamilyNunitoSans}>
                <Message id="LegendInfoText" />
            </LegendText>
        </g>
    );
};

const LegendText = styled('text')({
    fontSize: 8,
    fill: '#414447',
    textAnchor: 'start',
});

//#endregion
